import React from 'react';
import {recSender, flush, getCDP} from '@lib/logcenter';
import type {RECSender, CDP} from '@deezer/logcenter';
import {useTracking} from 'react-tracking';
import Cookies from 'js-cookie';

import eventLogs from '@lib/logcenter/eventLog';
import type {Trackable} from '@customTypes/track';
import {useTokensQuery} from '@graphql/generated';
import {useUserConsent} from '@tempo/cookie';
import {useUser} from './UserProvider';

type LogEventContextProps = {
	cdpInstance: React.MutableRefObject<CDP> | null;
	recInstance: React.MutableRefObject<RECSender> | null;
	eventLogs: typeof eventLogs | null;
};

const LogEventContext = React.createContext<LogEventContextProps>({
	cdpInstance: null,
	recInstance: null,
	eventLogs: null,
});

type LogEventProviderProps = {
	children: React.ReactNode;
	tracking?: Trackable;
};

const LogEventProvider = ({children, tracking}: LogEventProviderProps) => {
	const {user} = useUser();
	const [cdpInit, setCdpInit] = React.useState(false);
	const {Track: TrackingProvider, trackEvent} = useTracking();
	const {consentSettings} = useUserConsent();

	const {data} = useTokensQuery();

	const cdpInstance = React.useRef(getCDP());
	const screenViewLogSent = React.useRef<boolean>(false);
	const dzrUniqId = Cookies.get('dzr_uniq_id');

	React.useEffect(() => {
		if (tracking?.pagename && dzrUniqId && !screenViewLogSent.current) {
			trackEvent({
				pagename: tracking?.pagename,
				dzr_uniq_id: dzrUniqId,
				deezer_user_id: user?.id || 0,
			});
			screenViewLogSent.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tracking?.pagename, dzrUniqId]);

	const recInstance = React.useRef(recSender);

	React.useEffect(() => {
		window.onbeforeunload = () => {
			flush();
		};
	}, []);

	React.useEffect(() => {
		if (cdpInstance.current && !cdpInit) {
			cdpInstance.current.init();
			setCdpInit(true);
		}
	}, [cdpInit]);

	React.useEffect(() => {
		if (cdpInit) {
			if (user?.id !== '0') {
				cdpInstance.current.identify({userId: user?.id});
			}
		}
	}, [cdpInit, user?.id]);

	React.useEffect(() => {
		if (cdpInit && consentSettings) {
			cdpInstance.current.setConsent(consentSettings);
		}
	}, [consentSettings, cdpInit]);

	React.useEffect(() => {
		if (recInstance.current && data?.tokens.recToken?.token) {
			recInstance.current.setAuthToken(data?.tokens.recToken?.token);
		}
	}, [data]);

	const userId = user?.id || '0';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any

	const value = React.useMemo(
		() => ({
			cdpInstance,
			recInstance,
			eventLogs,
		}),
		[userId],
	);

	return (
		<TrackingProvider>
			<LogEventContext.Provider value={value}>
				{children}
			</LogEventContext.Provider>
		</TrackingProvider>
	);
};

export const useLogEvent = () => {
	const context = React.useContext<LogEventContextProps>(LogEventContext);
	if (!context) {
		throw new Error(
			'useLogEvent must be used wrapped inside a LogEventProvider',
		);
	}
	return context;
};

export default LogEventProvider;
