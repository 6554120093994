export const GROUP_TO_FETCH = 80;

export enum PLATFORM {
	DEEZER = 'Deezer',
	SPOTIFY = 'Spotify',
	APPLEMUSIC = 'Apple',
	YOUTUBE = 'YouTubeMusic',
}

export const MIN_ARTIST_TO_SELECT = 15;

export enum ONBOARDING_STEPS {
	ARTIST_PICKERS = 'artistPickers',
	IMPORT_LIBRARY = 'importLibrary',
	DEEZER_LIBRARY = 'deezerLibrary',
}
