import {HStack, Text, useColorMode} from '@tempo/core';
import {CheckCircleOutlinedIcon, WarningIcon} from '@tempo/icons';
import React from 'react';

export enum INTENT {
	SUCCESS,
	ERROR,
}

interface ToastWarningProps {
	children: React.ReactNode;
	intent: INTENT;
}

const ToastWarning: React.FC<ToastWarningProps> = ({children, intent}) => {
	const {colorMode} = useColorMode();

	return (
		<HStack
			color={colorMode === 'light' ? 'white' : 'black'}
			bg={colorMode === 'light' ? 'black' : 'white'}
			p={4}
			borderRadius="lg"
			spacing={2}
		>
			{intent === INTENT.SUCCESS && (
				<CheckCircleOutlinedIcon color="intent.success" boxSize={5} />
			)}
			{intent === INTENT.ERROR && (
				<WarningIcon color="intent.error" boxSize={5} />
			)}
			<Text whiteSpace="break-spaces" wordBreak="break-all">
				{children}
			</Text>
		</HStack>
	);
};

export default ToastWarning;
