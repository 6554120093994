import React, {useMemo} from 'react';
import type {GraphQLClient} from '@deezer/graphql-client';
import {createClient, GraphQLProvider} from '@deezer/graphql-client';

import type {
	PlaylistRawTrack,
	PlaylistTracksConnection,
} from '@graphql/generated';

import {useRouter} from 'next/router';

import {useAuth} from '@providers/AuthenticationProvider';

import config from '@modules/config';

interface GglProviderProps {
	children: React.ReactNode;
	offline?: boolean;
}
const GqlProvider = ({children, offline = false}: GglProviderProps) => {
	const {locale} = useRouter();
	const [, {getToken, invalidateCurrentToken}] = useAuth();

	const client = useMemo<GraphQLClient>(() => {
		return createClient({
			uri: `https://${config.get('host_pipe')}/api`,
			disableOffline: !offline,
			getLocale: () => locale as string,
			getToken,
			onTokenInvalid: invalidateCurrentToken,
			connectToDevTools: config.get('release') === 'development',
			typePolicies: {
				PrivateUser: {
					merge: true,
				},
				MusicTogetherGroup: {
					merge: true,
				},
				Playlist: {
					fields: {
						rawTracks: {
							merge(_, incoming: PlaylistRawTrack[]) {
								return [...incoming];
							},
						},
						tracks: {
							merge(_, incoming: PlaylistTracksConnection[]) {
								return incoming;
							},
						},
					},
				},
			},
		});
	}, [getToken, invalidateCurrentToken, locale, offline]);

	return <GraphQLProvider client={client}>{children}</GraphQLProvider>;
};
export default GqlProvider;
