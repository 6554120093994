import {MobileOS} from '@customTypes/mobile';

const userAgent =
	typeof window !== 'undefined' ? navigator.userAgent : undefined;

export const IS_MOBILE = userAgent?.match(
	/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i,
);
export const IS_DESKTOP = !IS_MOBILE;

export const getDevice = () => {
	const userAgent =
		typeof window !== 'undefined' ? navigator.userAgent : undefined;

	if (userAgent) {
		const isAndroid = /Android/.test(userAgent);
		const isIOS = /iPad|iPhone|iPod/.test(userAgent);

		if (isAndroid) return MobileOS.ANDROID;
		else if (isIOS) return MobileOS.IOS;
	}

	return MobileOS.NULL;
};

export const makeDownloadAppUrl = () => {
	const platform = getDevice();

	if (platform === MobileOS.IOS) {
		return 'https://deezer.page.link/?link=https://www.deezer.com/musictogether/groups&apn=deezer.android.app&ofl=https://shaker.deezer.com/groups&ibi=com.deezer.Deezer&isi=292738169';
	}

	if (platform === MobileOS.ANDROID) {
		return 'https://play.google.com/store/apps/details?id=deezer.android.app&listing=shaker_android';
	}

	return 'https://www.deezer.com/devices/mobile';
};
