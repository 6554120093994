import type {FC} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import ToastImport from './ToastImport';

type ToastProgressTMMProps = {
	initialTime: number; // in seconds
	initialProgress: number;
	onComplete: () => void;
	linkToGroup?: string;
};

export const DELAY_BEFORE_HIDE = 30000; // 30s

const ToastProgressTMM: FC<ToastProgressTMMProps> = ({
	initialTime,
	initialProgress,
	linkToGroup,
	onComplete,
}) => {
	const [time, setTime] = useState(initialTime);
	const updateTimer = useRef<number>();

	const percentage =
		initialProgress +
		Math.floor(((100 - initialProgress) / initialTime) * (initialTime - time));

	const isComplete = time === 0;

	useEffect(() => {
		if (isComplete) {
			return onComplete();
		}

		updateTimer.current = window.setInterval(() => {
			setTime((time) => time - 1);
		}, 1000);

		return () => {
			clearInterval(updateTimer.current);
		};
	}, [isComplete, onComplete]);

	return (
		<ToastImport
			isComplete={isComplete}
			onComplete={onComplete}
			percentage={percentage}
			linkToGroup={linkToGroup}
		/>
	);
};

ToastProgressTMM.displayName = 'ToastProgressTMM';

export default ToastProgressTMM;
