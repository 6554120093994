export enum TransferProgressStatus {
	IN_PROGRESS = 'in progress',
	COMPLETED = 'completed',
	NOT_FOUND = 'not found',
	LOADING_LIBRARY = 'loading library',
}

const API_URL_TMM_GET_TRANSFER_PROGRESS =
	'https://api.tunemymusic.com/v2/api/GetTransferProgress';

type TransferProgress =
	| {
			status: TransferProgressStatus.IN_PROGRESS;
			progress: number;
			estimated_seconds_left: number;
	  }
	| {
			status: TransferProgressStatus.COMPLETED;
	  }
	| {
			status: TransferProgressStatus.NOT_FOUND;
	  }
	| {
			status: TransferProgressStatus.LOADING_LIBRARY;
	  };

export const getTransferProgress = async (
	transferId: string,
): Promise<TransferProgress> => {
	const tmmTransferProgress = await fetch(API_URL_TMM_GET_TRANSFER_PROGRESS, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			transferId,
		}),
	}).catch((e) => {
		throw e;
	});

	if (!tmmTransferProgress.ok || tmmTransferProgress.status !== 200) {
		throw new Error(`TMM: response error`);
	}

	return tmmTransferProgress.json() as Promise<TransferProgress>;
};
