import {Image, type ImageProps} from '@tempo/core';
import React from 'react';

const Cover: React.FC<ImageProps> = (props) => (
	<Image
		width="80px"
		height="80px"
		alt=""
		mx="auto"
		mt="spacing.m"
		borderRadius="xs"
		{...props}
	/>
);

export default Cover;
