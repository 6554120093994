import React from 'react';
import {Tooltip} from '@tempo/core';
import {useTranslation} from 'next-i18next';

type CopyToClipboardProps = {
	hasCopied: boolean;
};

const CopyToClipboard: React.FC<
	React.PropsWithChildren<CopyToClipboardProps>
> = ({hasCopied, children}) => {
	const {t} = useTranslation();

	return (
		<Tooltip
			label={t('infomessage_text_linkcopiedtoyourclipboard_web')}
			placement="top"
			isOpen={hasCopied}
		>
			{children}
		</Tooltip>
	);
};

CopyToClipboard.displayName = 'CopyToClipboard';

export default CopyToClipboard;
