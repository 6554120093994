import {AuthBase, QUERY_PARAMS_TYPE, WithArlLogin} from '@deezer/auth';

import config from '@modules/config';

const Auth = WithArlLogin(AuthBase);

const authInstance = new Auth({
	tokenOutputType: QUERY_PARAMS_TYPE.PAYLOAD,
	refreshTokenOutputType: QUERY_PARAMS_TYPE.COOKIE,
	host: config.get('host_authent'),
});

export default authInstance;
