import React from 'react';

import authInstance from '@lib/auth/authClient';
import authInstanceAnonymous from '@lib/auth/authAnonymousClient';

import type {AuthProviderValue} from '@customTypes/auth';
import {AUTH_STATUS} from '@customTypes/auth';

const AuthContext = React.createContext<AuthProviderValue | null>(null);

type AuthProviderProps = {
	children: React.ReactNode;
};

const AuthProvider: React.FC<React.PropsWithChildren<AuthProviderProps>> = ({
	children,
}) => {
	const [authState, setAuthState] = React.useState<AUTH_STATUS>(
		AUTH_STATUS.LOADING,
	);

	const loginWithArl = React.useCallback(async () => {
		try {
			const accessToken = (await authInstance.loginWithArl()) ?? '';
			setAuthState(AUTH_STATUS.LOGGED_AS_USER);

			return accessToken || '';
		} catch (error) {
			const accessToken =
				(await authInstanceAnonymous.loginAsAnonymous()) ?? '';
			setAuthState(AUTH_STATUS.LOGGED_AS_ANONYMOUS);
			return accessToken;
		}
	}, []);

	const getToken = React.useCallback(async (): Promise<string> => {
		try {
			const accessToken = (await authInstance.getToken()) ?? '';
			setAuthState(AUTH_STATUS.LOGGED_AS_USER);
			return accessToken;
		} catch (e) {
			return await loginWithArl();
		}
	}, [loginWithArl]);

	const logout = React.useCallback(async () => {
		setAuthState(AUTH_STATUS.LOADING);
		await authInstance.logout();
	}, []);

	React.useEffect(() => {
		getToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const value = React.useMemo<AuthProviderValue>(
		() => [
			{authStatus: authState},
			{
				getToken,
				logout,
				loginWithArl,
				invalidateCurrentToken: () => authInstance.invalidateCurrentToken(),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[authState],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = (): AuthProviderValue => {
	const authContext = React.useContext(AuthContext);

	if (!authContext) {
		throw new Error('useAuthContext must be used whithin a AuthContext');
	}

	return authContext;
};
