import {type TextProps, Text} from '@tempo/core';
import React from 'react';

const SectionTitle: React.FC<TextProps> = ({children, ...rest}) => (
	<Text
		as="h4"
		variant="body.m.default"
		color="text.neutral.primary.default"
		px="spacing.l"
		mt="spacing.m"
		mb="spacing.s"
		isTruncated
		{...rest}
	>
		{children}
	</Text>
);

export default SectionTitle;
