import React from 'react';
import {useAuth} from '@providers/AuthenticationProvider';
import {useRouter} from 'next/router';

import {AUTH_STATUS} from '@customTypes/auth';

import useGatekeeps from '@hooks/useGatekeeps';
import {GATEKEEP} from '@customTypes/gatekeep';

interface ProtectedRouteProps {
	children: React.ReactNode;
	protectedRoutes: string[];
}

const ProtectedRouteProvider = ({
	children,
	protectedRoutes,
}: ProtectedRouteProps) => {
	const router = useRouter();
	const [{authStatus}] = useAuth();
	const gatekeepMusicTogether = useGatekeeps(GATEKEEP.MUSIC_TOGETHER);

	const pathIsProtected = protectedRoutes.indexOf(router.pathname) !== -1;
	const isLandingPage = router.pathname === '/';
	const isLoggedAsAnonymous = authStatus === AUTH_STATUS.LOGGED_AS_ANONYMOUS;

	React.useEffect(() => {
		if (!gatekeepMusicTogether.loading && !gatekeepMusicTogether.isOpen) {
			window.location.href = 'https://www.deezer.com';
		}

		if (
			(authStatus !== AUTH_STATUS.LOADING &&
				isLoggedAsAnonymous &&
				pathIsProtected) ||
			(router.pathname === '/bypass' &&
				!window.location.hostname.includes('deezerdev'))
		) {
			router.push('/');
		}

		if (
			authStatus !== AUTH_STATUS.LOADING &&
			!isLoggedAsAnonymous &&
			isLandingPage
		) {
			router.push('/groups');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authStatus, isLoggedAsAnonymous, pathIsProtected, gatekeepMusicTogether]);

	if (
		((authStatus === AUTH_STATUS.LOADING || isLoggedAsAnonymous) &&
			pathIsProtected) ||
		gatekeepMusicTogether.loading
	) {
		return null;
	}

	return <>{children}</>;
};

export default ProtectedRouteProvider;
