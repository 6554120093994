import React from 'react';
import {CookieBanner} from '@tempo/cookie';
import {useRouter} from 'next/router';
import config from '@modules/config';
import {getISOLocaleFromLocale} from '@deezer/deezer-compatibility';
import buildConfig from 'build-config.json';
import {useUser} from '@providers/UserProvider';

const ConsentScreen = () => {
	const {locale} = useRouter();
	const {user} = useUser();

	const gdprLang = getISOLocaleFromLocale(locale ?? 'en-US');

	return (
		<CookieBanner
			scriptUrl={`${buildConfig.host_commons_assets_ssl}/js/gdpr-appliance.js`}
			userID={user?.id || '0'}
			lang={gdprLang}
			cookieDomain={config.get('host_cookie')}
			gatewayHost={config.get('deezerApiUrl')}
		/>
	);
};

export default ConsentScreen;
