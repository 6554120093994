import type {ReactNode} from 'react';
import React from 'react';
import Head from 'next/head';
import {useTranslation} from 'next-i18next';

type Props = {
	children?: ReactNode;
};

const Layout: React.FC<Props> = ({children}) => {
	const {t} = useTranslation();

	return (
		<>
			<Head>
				<title>{t('shakerearlyaccesslanding_text_seotitle_web')}</title>
				<meta charSet="utf-8" />
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			</Head>
			{children}
		</>
	);
};

export default Layout;
