import React from 'react';
import Head from 'next/head';
import type {AppProps} from 'next/app';
import {useRouter} from 'next/router';
import {appWithTranslation} from 'next-i18next';
import configNextI18Next from 'next-i18next.config';

import {init as initSentry} from '@sentry/react';

import AppRoot from '@components/AppRoot';
import type {Trackable} from '@customTypes/track';

import './global.scss';

if (typeof window !== 'undefined' && process.env.SENTRY_DSN) {
	initSentry({
		enabled: process.env.NODE_ENV === 'production',
		dsn: process.env.SENTRY_DSN,
	});
}

type PageProps = {
	tracking: Trackable;
};

const App: React.FC<AppProps<PageProps>> = ({Component, pageProps}) => {
	const {locale, locales, route} = useRouter();

	return (
		<>
			<Head>
				<>
					{(locales || [])
						.filter((l) => l !== locale)
						.map((locale) => (
							<link
								key={locale}
								rel="alternate"
								hrefLang={locale}
								href={`/${locale}${route}`}
							/>
						))}
				</>
			</Head>
			<AppRoot tracking={pageProps.tracking}>
				<Component {...pageProps} />
			</AppRoot>
		</>
	);
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithTranslation(App, configNextI18Next);
