import {type FlexProps, Flex} from '@tempo/core';
import React from 'react';
import SectionTitle from './SectionTitle';

type SubComponents = {
	Title: typeof SectionTitle;
};

const Section: React.FC<FlexProps> & SubComponents = ({children, ...rest}) => (
	<Flex
		direction="column"
		borderBottom="1px solid"
		borderColor="divider.neutral.primary.default"
		py="spacing.m"
		_last={{
			border: 'none',
		}}
		{...rest}
	>
		{children}
	</Flex>
);

Section.Title = SectionTitle;

export default Section;
