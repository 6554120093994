import {DrawerHeader} from '@tempo/core';
import React from 'react';
import Title from './Title';
import SubTitle from './SubTitle';
import Cover from './Cover';

export enum HEADER_SIZE {
	S = 'S',
	M = 'M',
}

type HeaderProps = {
	size?: HEADER_SIZE;
};

type SubComponents = {
	Title: typeof Title;
	SubTitle: typeof SubTitle;
	Cover: typeof Cover;
};

const Header: React.FC<React.PropsWithChildren<HeaderProps>> &
	SubComponents = ({children, size = HEADER_SIZE.M}) => (
	<DrawerHeader
		borderBottom="1px solid"
		borderColor="divider.neutral.primary.default"
		py={size === HEADER_SIZE.M ? 'spacing.xl' : 'spacing.l'}
		px="spacing.l"
		textAlign="center"
	>
		{children}
	</DrawerHeader>
);

Header.Title = Title;
Header.SubTitle = SubTitle;
Header.Cover = Cover;

export default Header;
