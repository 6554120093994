import Storage from '@deezer/storage';
import ImportTMMProvider, {
	StorageTransferKey,
} from '@providers/ImportTMMProvider';
import {useUser} from '@providers/UserProvider';
import {useRouter} from 'next/router';
import React from 'react';

/**
 * Avoid us to render all TMM logic when not needed
 */
const SwitchTMM: React.FC<React.PropsWithChildren> = ({children}) => {
	const router = useRouter();
	const {user, shouldBeOnboarded} = useUser();

	const storage = React.useMemo(() => new Storage(), []);

	const shouldRenderTMM = React.useMemo(() => {
		const hasTransferInProgress = !!storage.get<string>(
			StorageTransferKey.TRANSFER_ID,
		);

		const {transferId} = router.query;

		return user && (hasTransferInProgress || transferId);
	}, [router.query, storage, user]);

	React.useEffect(() => {
		if (
			!shouldRenderTMM &&
			shouldBeOnboarded &&
			!router.asPath.includes('/onboarding')
		) {
			if (router.query.groupId) {
				router.push(`/onboarding?groupId=${router.query.groupId}`);
			} else {
				router.push('/onboarding');
			}
		}
	}, [router, shouldBeOnboarded, shouldRenderTMM]);

	if (shouldRenderTMM) return <ImportTMMProvider>{children}</ImportTMMProvider>;

	if (shouldBeOnboarded && !router.asPath.includes('/onboarding')) return null;

	return children;
};

export default SwitchTMM;
