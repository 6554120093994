import React from 'react';
import type {InvitationLink} from '@hooks/useFetchInvitationLink';

type InvitationLinkProviderValue = {
	invitationLink: InvitationLink;
	onOpenBottomSheetShare: (groupId: string) => void;
};

export const invitationLinkInitalState = {
	url: '',
	qrCodeUrl: '',
};

export const InvitationLinkContext =
	React.createContext<InvitationLinkProviderValue>({
		invitationLink: invitationLinkInitalState,
		onOpenBottomSheetShare: () => {},
	});

export const useInvitationLink = (): InvitationLinkProviderValue => {
	const invitationLinkContext = React.useContext(InvitationLinkContext);
	return invitationLinkContext;
};
