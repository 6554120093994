/**
 * Class to handle REC logs for preview streams
 * Schema: https://github.deezerdev.com/Deezer/deezer-message-schema/blob/master/Kafka/PublicMessageSchema/deezer/public/remote/playback/stream-1.1.0.json
 */

// Classes
import {RECLog, RECLogContext} from '@deezer/logcenter';

// Types
import type {StreamV1} from '@deezer/logcenter';
import type {StreamLogConstructorOptions, StreamLogInternals} from './types';

// Utilities
import {v4 as uuid} from 'uuid';

export const STREAM_LOG_TYPE = 'playback.stream';
export const STREAM_LOG_VERSION = '1.1.0';

const recLogContext = new RECLogContext({
	name: 'Deezer',
	version: '1.0.0',
});

export default class StreamLog
	extends RECLog<StreamV1>
	implements StreamLogInternals
{
	readonly createdAt;
	readonly isRestricted: boolean = true; // We are only playing previews in music-together as of Dec. 12, 2022
	readonly isSynchronized: boolean = false;
	readonly listeningDuration;
	readonly listenType;
	readonly media;
	readonly streamContext;
	readonly streamID;
	readonly userId;

	constructor({
		createdAt,
		isRestricted,
		isSynchronized,
		listeningDuration,
		listenType,
		media,
		userId,
		streamContext,
	}: StreamLogConstructorOptions) {
		super({
			type: STREAM_LOG_TYPE,
			version: STREAM_LOG_VERSION,
			...recLogContext,
		});

		this.createdAt = createdAt ?? Math.round(Date.now() / 1000);
		this.isRestricted = isRestricted ?? this.isRestricted;
		this.isSynchronized = isSynchronized ?? this.isSynchronized;
		this.listeningDuration = listeningDuration;
		this.listenType = listenType;
		this.media = media;
		this.streamContext = streamContext;
		this.streamID = uuid();
		this.userId = userId;
	}

	serialize(): StreamV1 {
		const {
			createdAt: created_at,
			isRestricted: is_restricted,
			isSynchronized: is_synchronized,
			listeningDuration: listening_duration,
			listenType: listen_type,
			media,
			streamContext,
			streamID: stream_id,
			userId: user_id,
			...rest
		} = this;
		const {id: media_id, type: media_type} = media;
		const {id: context_id, type: context_type} = streamContext;

		return {
			...super.serialize.call(rest),
			created_at,
			is_restricted,
			is_synchronized,
			listen_type,
			listening_duration,
			media: {media_id, media_type},
			stream_context: {context_id, context_type},
			stream_id,
			user_id,
		};
	}
}
