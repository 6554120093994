import {TabType} from '@customTypes/tab';
import {useRouter} from 'next/router';

const useActiveTab = () => {
	const {query} = useRouter();

	switch (query?.tab) {
		case 'mix':
			return TabType.MIX;
		case 'playlist':
			return TabType.PLAYLIST;
		case 'stats':
			return TabType.STATS;
		default:
			break;
	}
};

export default useActiveTab;
