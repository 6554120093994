import LogCenter, {
	MemoryStorage,
	DirectStrategy,
	RECSender,
	getBatchConfiguration,
	BatchStrategy,
	CDP,
	CDPSender,
} from '@deezer/logcenter';
import config from '@modules/config';

const memoryStorage = new MemoryStorage();

const batchConfiguration = getBatchConfiguration({
	maxBatchSize: 3,
	autoSendInterval: 1 * 60 * 1000,
	ignoredLogTypes: ['cdp'],
});

const buildDefaultCDP = () => {
	return new CDP(config.get('segment_api_key'), {
		logger: (type, error) =>
			// eslint-disable-next-line no-console
			typeof error === 'object' ? console.error({...error, type}) : undefined,
	});
};

let cdp: CDP;
export const getCDP = (): CDP => {
	if (!cdp) {
		cdp = buildDefaultCDP();
	}
	return cdp;
};

export const cdpSender = new CDPSender(getCDP());

export const recSender = new RECSender(
	`https://${config.get('host_rec')}/1.0/events/`,
	'',
	batchConfiguration,
);

const batchStrategy = new BatchStrategy(
	recSender,
	memoryStorage,
	batchConfiguration,
);

export const flush = batchStrategy.flush.bind(batchStrategy);

const directStrategy = new DirectStrategy(cdpSender, memoryStorage, {
	handledLogTypes: ['cdp'],
});

export const logCenter = new LogCenter(
	[directStrategy, batchStrategy],
	memoryStorage,
);
