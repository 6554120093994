import React from 'react';
import {Button, Flex, Text, Heading} from '@tempo/core';
import {RefreshIcon} from '@tempo/icons';
import {useTranslation} from 'next-i18next';

type ErrorProps = {
	retry: () => void;
};

const Error: React.FC<ErrorProps> = ({retry}) => {
	const {t} = useTranslation();

	return (
		<Flex direction="column" justify="center" align="center" w="100%" h="100%">
			<RefreshIcon boxSize="icon.m" mb="spacing.xl" />
			<Heading
				variant="heading.l"
				color="text.neutral.primary.default"
				mb="spacing.l"
			>
				{t('errormessage_title_unabletoloadthispage_web')}
			</Heading>
			<Text
				variant="body.l.default"
				color="text.neutral.secondary.default"
				mb="spacing.xl"
				textAlign="center"
			>
				{t('errormessage_text_pleasetryagainlater_web')}
			</Text>
			<Button onClick={retry} colorScheme="primary">
				{t('generic_action_retry_web')}
			</Button>
		</Flex>
	);
};

export default Error;
