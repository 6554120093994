import React from 'react';
import {motion} from 'framer-motion';
import {Flex} from '@tempo/core';

const AnimateToast = motion(Flex);

const ToastContainer: React.FC<React.PropsWithChildren> = ({children}) => (
	<AnimateToast
		position="fixed"
		bottom={5}
		left="50%"
		zIndex={5}
		direction="row"
		align="center"
		bg="background.neutral.tertiary.default"
		width="90vw"
		maxWidth="400px"
		borderRadius="m"
		p={4}
		initial={{
			opacity: 0,
			scale: 0.85,
			translateX: '-50%',
			y: '50px',
		}}
		animate={{
			opacity: 1,
			scale: 1,
			y: 0,
			transition: {
				duration: 0.4,
				ease: [0.4, 0, 0.2, 1],
			},
		}}
		exit={{
			opacity: 0,
			scale: 0.85,
			transition: {
				duration: 0.2,
				ease: [0.4, 0, 1, 1],
			},
		}}
	>
		{children}
	</AnimateToast>
);

export default ToastContainer;
