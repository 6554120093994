import {type HeadingProps, Heading} from '@tempo/core';
import React from 'react';

const Title: React.FC<HeadingProps> = ({children, ...rest}) => (
	<Heading
		as="h2"
		variant="heading.m"
		color="text.neutral.primary.default"
		isTruncated
		{...rest}
	>
		{children}
	</Heading>
);

export default Title;
