import React from 'react';
import {Flex, type FlexProps, forwardRef, Text} from '@tempo/core';

type ButtonProps = FlexProps & {
	icon: React.ReactElement;
};

const Button = forwardRef<ButtonProps, 'button'>(
	({children, icon, ...props}, ref) => (
		<Flex
			as="button"
			ref={ref}
			align="center"
			px="spacing.l"
			py="spacing.m"
			mt="spacing.xs"
			_first={{
				mt: 'auto',
			}}
			_hover={{
				bg: 'background.neutral.secondary.pressed',
			}}
			_focus={{
				bg: 'background.neutral.secondary.pressed',
			}}
			{...props}
		>
			{React.cloneElement(icon, {
				boxSize: 'size.s',
				mr: 'spacing.l',
				color: 'icon.neutral.secondary.default',
			})}

			<Text variant="body.l.default" color="text.neutral.primary.default">
				{children}
			</Text>
		</Flex>
	),
);

Button.displayName = 'BottomSheet.Button';

export default Button;
