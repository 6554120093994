import {GATEKEEP} from '@customTypes/gatekeep';
import {useGatekeepsQuery} from '@graphql/generated';

const useGatekeeps = (gatekeep: string) => {
	const {data, loading} = useGatekeepsQuery({
		variables: {
			names: [
				GATEKEEP.MUSIC_TOGETHER,
				GATEKEEP.MUSIC_TOGETHER_CREATE,
				GATEKEEP.MUSIC_TOGETHER_JOIN,
				GATEKEEP.MUSIC_TOGETHER_RELIVE,
			],
		},
	});

	return {
		isOpen: data?.gatekeeps?.gatekeeps.find((e) => e.name === gatekeep)?.isOpen,
		loading,
	};
};

export default useGatekeeps;
