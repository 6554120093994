import React, {useContext} from 'react';

import {
	WebviewBridgeEmitter,
	WebSender,
	// WebviewBridgeListener,
	// BridgeEvents,
} from '@deezer/webview-bridge';

type BridgeProviderValue = WebviewBridgeEmitter | null;

const BridgeContext = React.createContext<BridgeProviderValue | void>(
	undefined,
);

const BridgeProvider: React.FC<React.PropsWithChildren> = ({children}) => {
	const bridgeAvailable = window.self !== window.top;

	const value = bridgeAvailable
		? new WebviewBridgeEmitter(new WebSender())
		: null;

	// TO DO implement other listeners like this below

	// React.useEffect(() => {
	// 	const listener = new WebviewBridgeListener();

	// 	listener.on(BridgeEvents.STOP_AUDIO, () => {
	// 		console.log('STOP_AUDIO');
	// 	});
	// 	return () => {
	// 		listener.off(BridgeEvents.STOP_AUDIO, () => {
	// 			console.log('STOP_AUDIO');
	// 		});
	// 		listener.destroy();
	// 	};
	// }, []);

	return (
		<BridgeContext.Provider value={value}>{children}</BridgeContext.Provider>
	);
};

export default BridgeProvider;

export const useBridge = (): BridgeProviderValue => {
	const bridgeContext = useContext(BridgeContext);

	if (!bridgeContext && bridgeContext !== null) {
		throw new Error('useBridge must be used whithin a BridgeProvider');
	}

	return bridgeContext;
};
