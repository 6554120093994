import React from 'react';
import type {MeQuery} from '@graphql/generated';
import {useMeQuery} from '@graphql/generated';
import Container from '@components/Container';
import ErrorComponent from '@components/Error';
import type {ONBOARDING_STEPS} from '@constants';

type UserProviderState = {
	data: MeQuery | null;
};

export const UserContext = React.createContext<UserProviderState>({
	data: null,
});

type UserProviderProps = {
	children: React.ReactNode;
};

const UserProvider: React.FC<UserProviderProps> = ({children}) => {
	const [hasError, setHasError] = React.useState(false);

	const {data, loading, refetch} = useMeQuery({
		onError: () => {
			setHasError(true);
		},
	});

	if (hasError) {
		return (
			<Container>
				<ErrorComponent retry={() => refetch()} />
			</Container>
		);
	}

	if (loading) {
		return null;
	}

	return (
		<UserContext.Provider value={{data: data || null}}>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;

export const useUserContext = (): UserProviderState => {
	const userContext = React.useContext(UserContext);

	if (!userContext) {
		throw new Error('useUserContext must be used whithin a UserProvider');
	}

	return userContext;
};

export const useUser = () => {
	const context = useUserContext();

	if (!context.data) {
		return {
			user: null,
			shouldBeOnboarded: false,
			onboardingSteps: [] as string[],
		};
	}

	return {
		user: context.data.me?.user,
		shouldBeOnboarded: context.data.me?.onboarding.shouldBeOnboarded,
		onboardingSteps: context.data.onboardingSteps?.chooseNextStep
			.steps as ONBOARDING_STEPS[],
	};
};
