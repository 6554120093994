import React from 'react';

import {useClipboard} from '@tempo/core';
import {IS_DESKTOP} from '@lib/deviceDetect';
import {useTranslation} from 'next-i18next';
import {CustomEventActions} from '@lib/logcenter/eventLog';
import {useLogEvent} from '@providers/LogEventProvider';

type UseHandleShareGroupInvitationOptions = {
	onCopyCallback?: () => void;
};

const TIMEOUT_SHARE = 3000;

const useHandleShareGroupInvitation = ({
	onCopyCallback,
}: UseHandleShareGroupInvitationOptions) => {
	const {t} = useTranslation();
	const {onCopy, value, setValue, hasCopied} = useClipboard('', TIMEOUT_SHARE);
	const {eventLogs} = useLogEvent();

	const baseData = {
		title: t('musictogethersharingmenu_title_invitefriends_web'),
		description: t('whatsappsharing_text_shakerinvitation_web'),
	};

	React.useEffect(() => {
		if (value) {
			onCopy();

			if (hasCopied) {
				setTimeout(() => {
					onCopyCallback?.();
				}, TIMEOUT_SHARE);
			}

			return () => {
				setValue('');
			};
		}
	}, [hasCopied, onCopy, setValue, value, onCopyCallback]);

	const prepareShare = (groupId: string, url: string) => {
		eventLogs?.logCustomEvent({
			groupId,
			action: CustomEventActions.inviteMember,
		});

		if (!navigator.share) {
			if (IS_DESKTOP) {
				setValue(url);
			}

			return;
		}

		if (navigator.canShare(baseData)) {
			navigator
				.share({
					...baseData,
					url,
				})
				.then(() => {
					setTimeout(() => {
						onCopyCallback?.();
					}, TIMEOUT_SHARE);
				});
		}
	};

	return {
		prepareShare,
		hasCopied,
	};
};

export default useHandleShareGroupInvitation;
