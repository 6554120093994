import {useApiCall} from '@deezer/react-legacy-api';
import {useState, useCallback} from 'react';

export enum SHARE_TYPE {
	GROUP_JOIN = 'group_join',
	TRACK = 'track',
}

type useShortenUrlParams = {
	baseUrl?: string;
	itemId: string;
	type: SHARE_TYPE;
	title?: string;
	description?: string;
	onBeforeCreate?: (baseUrl?: URL) => void;
};

type useShortenUrl = {
	shortenUrl: string | null;
	getUrl: (params: useShortenUrlParams) => Promise<string | null>;
};

export function useShortenUrl(): useShortenUrl {
	const apiCall = useApiCall();

	const [shortenUrl, setShortenUrl] = useState<string | null>(null);

	const getUrl = useCallback(
		({
			baseUrl,
			itemId,
			title,
			description,
			type,
			onBeforeCreate,
		}: useShortenUrlParams) => {
			const _baseUrl = baseUrl ? new URL(baseUrl) : undefined;

			// For tracking purpose
			if (_baseUrl) {
				onBeforeCreate?.(_baseUrl);
			}

			return apiCall({
				method: 'share.getDynamicLink',
				data: {
					link: _baseUrl,
					type,
					id: itemId,
					sharing_platform: 'clipboard',
					sharing_format: 'generic',
					shorten_social_title: title || undefined,
					shorten_social_description: description || undefined,
				},
			}).then((url) => {
				if (typeof url === 'string') {
					setShortenUrl(url);
					return url;
				}

				return null;
			});
		},
		[apiCall],
	);

	return {shortenUrl, getUrl};
}
