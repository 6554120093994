import type authInstance from '@lib/auth/authClient';

export enum AUTH_STATUS {
	LOADING,
	LOGGED_AS_USER,
	LOGGED_AS_ANONYMOUS,
}

export type AuthProviderValue = [
	status: {
		authStatus: AUTH_STATUS;
	},
	authMethods: {
		getToken: () => Promise<string>;
		invalidateCurrentToken: typeof authInstance.invalidateCurrentToken;
		logout: typeof authInstance.logout;
	},
];
