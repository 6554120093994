import {type TextProps, Text} from '@tempo/core';
import React from 'react';

const SubTitle: React.FC<TextProps> = ({children, ...rest}) => (
	<Text
		as="h3"
		variant="body.s.default"
		color="text.neutral.secondary.default"
		isTruncated
		{...rest}
	>
		{children}
	</Text>
);

export default SubTitle;
