import React from 'react';
import {useTranslation} from 'next-i18next';
import Toast, {INTENT} from '@components/Toast';
import {useDisclosure, useToast} from '@tempo/core';
import {useFetchInvitationLink} from '@hooks/useFetchInvitationLink';
import type {InvitationLink} from '@hooks/useFetchInvitationLink';
import BottomSheetShare from '@components/BottomSheetShare';
import {
	InvitationLinkContext,
	invitationLinkInitalState,
} from './InvitationLinkContext';
import {useUser} from './UserProvider';
import {useLogEvent} from './LogEventProvider';

const InvitationLinkContextProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const {t} = useTranslation();
	const {eventLogs} = useLogEvent();
	const {user} = useUser();
	const {getInvitationLink} = useFetchInvitationLink();
	const toast = useToast({
		duration: 3000,
	});

	const [invitationLink, setInvitationLink] = React.useState<InvitationLink>(
		invitationLinkInitalState,
	);

	const [currentGroupId, setCurrentGroupId] = React.useState<string>('');

	const {
		isOpen: isBottomSheetShareOpened,
		onOpen,
		onClose: onCloseBottomSheetShare,
	} = useDisclosure();

	const handleShare = React.useCallback(
		(itemId: string) => {
			if (currentGroupId !== itemId && user?.id) {
				getInvitationLink({
					itemId,
					userId: user.id,
				})
					.then((invitationLink) => {
						setCurrentGroupId(itemId);
						setInvitationLink(invitationLink);
					})
					.catch(() => {
						toast({
							render: () => (
								<Toast intent={INTENT.ERROR}>
									{t('errormessage_text_anerroroccurredpleasetryagain_web')}
								</Toast>
							),
						});
					});
			}
		},
		[currentGroupId, getInvitationLink, t, toast, user?.id],
	);

	const onOpenBottomSheetShare = React.useCallback(
		(groupId: string) => {
			onOpen();
			handleShare(groupId);
			eventLogs?.logScreenViewSharingMenu({
				groupId,
				userId: user?.id,
			});
		},
		[eventLogs, handleShare, onOpen, user?.id],
	);

	const value = React.useMemo(
		() => ({
			invitationLink,
			onOpenBottomSheetShare,
		}),
		[invitationLink, onOpenBottomSheetShare],
	);

	return (
		<InvitationLinkContext.Provider value={value}>
			{children}
			<BottomSheetShare
				isBottomSheetShareOpened={isBottomSheetShareOpened}
				onCloseBottomSheetShare={onCloseBottomSheetShare}
				groupId={currentGroupId}
			/>
		</InvitationLinkContext.Provider>
	);
};

export default InvitationLinkContextProvider;
