import React from 'react';
import {useRouter} from 'next/router';
import Storage from '@deezer/storage';

import {useTranslation} from 'next-i18next';
import ToastProgressTMM from '@components/ToastImportTMM';
import {
	TransferProgressStatus,
	getTransferProgress,
} from '@lib/getTransferProgress';
import ToastImport from '@components/ToastImportTMM/ToastImport';
import Toast, {INTENT} from '@components/Toast';
import {
	MeDocument,
	useValidateOnboardingImportLibraryStepMutation,
} from '@graphql/generated';
import {useToast} from '@tempo/core';

export enum StorageTransferKey {
	TRANSFER_ID = 'transferId',
	LAST_GROUP_JOINED = 'lastGroupJoined',
}

const INTERVAL_REFETCH_PROGRESS = 2000;

const ImportTMMProvider: React.FC<React.PropsWithChildren> = ({children}) => {
	const {t} = useTranslation();
	const router = useRouter();

	const toast = useToast({
		duration: 3000,
	});

	// Update shaker onboarding
	const [validateOnboardingImportLibraryStep] =
		useValidateOnboardingImportLibraryStepMutation({
			variables: {
				input: {hasSkipped: false},
			},
			update: (cache, nextData) => {
				if (nextData?.data?.validateOnboardingImportLibraryStep) {
					cache.updateQuery(
						{
							query: MeDocument,
						},
						(data) => ({
							...data,
							me: {
								...data.me,
								onboarding: {
									...data.me.onboarding,
									shouldBeOnboarded:
										nextData.data?.validateOnboardingImportLibraryStep
											.onboarding.shouldBeOnboarded,
								},
							},
						}),
					);
				}
			},
			onError: () => {
				return toast({
					render: () => (
						<Toast intent={INTENT.ERROR}>
							{t('errormessage_text_anerroroccurredpleasetryagain_web')}
						</Toast>
					),
				});
			},
		});

	const storage = React.useMemo(() => new Storage(), []);
	const transferIdStorage = storage.get<string>(StorageTransferKey.TRANSFER_ID);
	const lastGroupJoinedStorage = storage.get<string>(
		StorageTransferKey.LAST_GROUP_JOINED,
	);

	// To store data sent by TMM
	const [timeTransfer, setTimeTransfer] = React.useState(1);
	const [currentProgress, setCurrentProgress] = React.useState(0);

	// To handle state of toast
	const [hasTransferAlreadyCompleted, setHasTransferAlreadyCompleted] =
		React.useState(false);
	const hasTransferInProgress = React.useRef(false);

	// To retry TMM progress request
	const [needRefetchProgress, setNeedRefetchProgress] = React.useState(false);
	const intervalRefetchTMM = React.useRef<number>();

	// Move transferId from query param to localStorage
	React.useEffect(() => {
		const {transferId, ...routerQuery} = router.query;

		if (typeof transferId === 'string' && transferId) {
			storage.set(StorageTransferKey.TRANSFER_ID, transferId);
			validateOnboardingImportLibraryStep();

			// Save it to display the group link when the import is finished
			if (routerQuery.groupId) {
				storage.set(StorageTransferKey.LAST_GROUP_JOINED, routerQuery.groupId);
			}

			// Remove transferId from query params
			router.replace({
				query: {...routerQuery},
			});
		}
	}, [router, storage, validateOnboardingImportLibraryStep]);

	const getTmmTransferProgress = React.useCallback(() => {
		if (transferIdStorage) {
			return getTransferProgress(transferIdStorage).then((transferProgress) => {
				if (transferProgress.status === TransferProgressStatus.IN_PROGRESS) {
					hasTransferInProgress.current = true;
					setTimeTransfer(transferProgress.estimated_seconds_left);
					setCurrentProgress(transferProgress.progress);

					if (needRefetchProgress) setNeedRefetchProgress(false);
				} else if (
					transferProgress.status === TransferProgressStatus.COMPLETED
				) {
					/**
					 * Sometimes TMM is really fast to import the library
					 * And when the user is redirected from TMM to Shaker
					 * He arrives with his library already imported
					 * So we want to display this toast to inform him
					 */
					setHasTransferAlreadyCompleted(true);

					if (needRefetchProgress) setNeedRefetchProgress(false);
				} else if (
					transferProgress.status === TransferProgressStatus.NOT_FOUND
				) {
					storage.remove(StorageTransferKey.TRANSFER_ID);
					storage.remove(StorageTransferKey.LAST_GROUP_JOINED);
					hasTransferInProgress.current = false;

					if (needRefetchProgress) setNeedRefetchProgress(false);
				} else if (
					transferProgress.status === TransferProgressStatus.LOADING_LIBRARY
				) {
					/**
					 * Sometimes TMM sent us this status that mean they don't have start the import
					 * So we will need to refetch until we get a real status of progress
					 */
					setNeedRefetchProgress(true);
				}
			});
		}
	}, [needRefetchProgress, storage, transferIdStorage]);

	// Get transfer progress with transferId from localStorage
	React.useEffect(() => {
		if (transferIdStorage) {
			getTmmTransferProgress();
		}
	}, [getTmmTransferProgress, transferIdStorage]);

	React.useEffect(() => {
		if (needRefetchProgress && transferIdStorage) {
			intervalRefetchTMM.current = window.setInterval(() => {
				getTmmTransferProgress();
			}, INTERVAL_REFETCH_PROGRESS);
		}

		return () => {
			clearInterval(intervalRefetchTMM.current);
		};
	}, [getTmmTransferProgress, needRefetchProgress, transferIdStorage]);

	return (
		<>
			{children}

			{hasTransferInProgress.current && (
				<ToastProgressTMM
					initialTime={timeTransfer}
					initialProgress={currentProgress}
					onComplete={() => {
						storage.remove(StorageTransferKey.TRANSFER_ID);
						hasTransferInProgress.current = false;
					}}
					linkToGroup={
						lastGroupJoinedStorage
							? `/group/${lastGroupJoinedStorage}`
							: undefined
					}
				/>
			)}

			{hasTransferAlreadyCompleted && (
				<ToastImport
					isComplete
					percentage={100}
					onHide={() => {
						storage.remove(StorageTransferKey.TRANSFER_ID);
						storage.remove(StorageTransferKey.LAST_GROUP_JOINED);
					}}
					linkToGroup={
						lastGroupJoinedStorage
							? `/group/${lastGroupJoinedStorage}`
							: undefined
					}
				/>
			)}
		</>
	);
};

export default ImportTMMProvider;
