import React from 'react';
import {useUser} from './UserProvider';
import {useGroupInfoQuery} from '@graphql/generated';
import {useRouter} from 'next/router';
import {TabType} from '@customTypes/tab';
import useActiveTab from '@hooks/useActiveTab';

type GroupProviderValue = {
	isMemberOfCurrentGroup: boolean;
	groupId: string;
	tabIndex: number;
	setTab: (tabIndex: number) => void;
};

const GroupContext = React.createContext<GroupProviderValue>({
	isMemberOfCurrentGroup: false,
	groupId: '',
	tabIndex: 0,
	setTab: () => {},
});

const GroupContextContextProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const {
		query: {groupId = ''},
		replace,
		pathname,
	} = useRouter();
	const {user} = useUser();
	const activeTab = useActiveTab();

	const [tabIndex, setTabIndex] = React.useState<TabType>(activeTab || 0);

	const setTab = React.useCallback(
		(tabIndex: number) => {
			setTabIndex(tabIndex);
			const tabName = TabType[tabIndex].toLowerCase().replace('_', '-');
			replace({
				pathname: pathname,
				query: {tab: tabName, groupId},
			});
		},

		[groupId, pathname, replace],
	);

	const {data, loading} = useGroupInfoQuery({
		variables: {
			musicTogetherGroupId: groupId as string,
		},
		skip: !groupId,
	});

	const isMemberOfCurrentGroup =
		data?.musicTogetherGroup?.members.edges.some(
			(member) => member.node?.id === user?.id,
		) || false;

	const value = React.useMemo(
		() => ({
			isMemberOfCurrentGroup,
			groupId: groupId as string,
			tabIndex,
			setTab,
		}),
		[groupId, isMemberOfCurrentGroup, setTab, tabIndex],
	);

	if (loading || !data) {
		return null;
	}

	return (
		<GroupContext.Provider value={value}>{children}</GroupContext.Provider>
	);
};

export default GroupContextContextProvider;

export const useGroup = (): GroupProviderValue => {
	const groupContext = React.useContext(GroupContext);
	return groupContext;
};
