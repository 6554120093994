import type {DzrUniqId} from '@hooks/useDzrUniqId';
import {logCenter} from '.';
import StreamLog from './streamMetrics';
import {PLATFORM} from '@constants';

const TYPE = 'cdp';

const baseLogScreen = (event: Record<string, unknown>) =>
	logCenter.log({
		type: TYPE,
		eventName: 'screen_view',
		...event,
	});

export enum CustomEventActions {
	generate = 'generate',
	selectMood = 'select_mood',
	refreshSuggestedMix = 'refresh_suggested_mix',
	viewGroupInformations = 'view_group_informations',
	viewTrackInformations = 'view_track_informations',
	confirmNewMix = 'confirm_new_mix',
	createGroup = 'create_group',
	joinGroup = 'join_group',
	clickStory = 'click_story',
	goDeezerPremium = 'go_deezer_premium',
	inviteMember = 'invite_member',
	downloadDeezer = 'download_deezer',
	viewMoreTracksNone = 'view_more_tracks_group_mix',
	viewMoreTracksParty = 'view_more_tracks_party_mix',
	viewMoreTracksChill = 'view_more_tracks_focus_mix',
	viewMoreTracksFocus = 'view_more_tracks_chill_mix',
	logOut = 'log_out',
}

export enum ScreenViewName {
	musicTogetherSongInformation = 'music_together_song_information',
	musicTogetherUserAffinity = 'music_together_user_affinity',
	musicTogetherGroupMembersList = 'music_together_group_members_list',
}

export enum SectionName {
	avatar = 'avatar',
	moreAbout = 'more_about',
}

export const logScreenViewGroups = ({userId}: {userId?: string}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_center',
			screen_view_id_type: 'user',
			screen_view_id: userId,
		},
	});
};

export const logScreenViewSuggestedTrackList = ({
	groupId,
	userId,
}: {
	userId?: string;
	groupId: string;
}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_group_suggested_tracklist',
			screen_view_id_type: 'group',
			screen_view_id: groupId,
		},
	});
};

export const logScreenViewCuratedTrackList = ({
	groupId,
	userId,
}: {
	userId?: string;
	groupId: string;
}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_group_curated_tracklist',
			screen_view_id_type: 'group',
			screen_view_id: groupId,
		},
	});
};

export const logScreenViewGroupInformations = ({
	groupId,
	userId,
}: {
	userId?: string;
	groupId: string;
}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_group_information',
			screen_view_id_type: 'group',
			screen_view_id: groupId,
		},
	});
};

export const logScreenViewGroupSetting = ({
	groupId,
	userId,
}: {
	userId?: string;
	groupId: string;
}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_group_setting',
			screen_view_id_type: 'group',
			screen_view_id: groupId,
		},
	});
};

export const logScreenJoinGroup = ({
	groupId,
	userId,
}: {
	userId?: string;
	groupId: string;
}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_join_group',
			screen_view_id_type: 'group',
			screen_view_id: groupId,
		},
	});
};

export const logScreenViewSharingMenu = ({
	groupId,
	userId,
}: {
	userId?: string;
	groupId: string;
}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_sharing_menu',
			screen_view_id_type: 'group',
			screen_view_id: groupId,
		},
	});
};

export const logScreenViewConversionModal = ({
	groupId,
	userId,
}: {
	userId?: string;
	groupId: string;
}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_download_app_popup',
			screen_view_id_type: 'group',
			screen_view_id: groupId,
		},
	});
};

export const logScreenViewLanding = ({userId}: {userId?: string}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_landing_page',
			screen_view_id_type: null,
			screen_view_id: null,
		},
	});
};

export const logNavigationAvatarBottomSheet = ({
	groupId,
	playlistId,
	userId,
}: {
	userId?: string;
	groupId: string;
	playlistId: string;
}) => {
	return logCenter.log({
		type: TYPE,
		eventName: 'music_together_used',
		user_id: userId || '0',
		ts: Math.round(Date.now() / 1000),
		customAttributes: {
			action: 'view_track_informations',
			group_id: groupId,
			playlist_id: playlistId,
		},
	});
};

export const logScreenViewTrackInformations = ({
	groupId,
	userId,
}: {
	userId?: string;
	groupId: string;
	pageUrl: string;
	referringPage?: string;
	dzrUniqId: DzrUniqId;
}) => {
	return baseLogScreen({
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: 'music_together_song_information',
			screen_view_id_type: 'group',
			screen_view_id: groupId,
		},
	});
};

export enum STREAM_CONTEXT {
	CURATED_TRACKLIST = 'musictogether_curatedtracklist_radio',
	SUGGESTED_TRACKLIST = 'musictogether_suggestedtracklist_radio',
}

export const logStream = ({
	groupId,
	userId,
	trackId,
	listeningTime,
	startTime,
	context,
}: {
	groupId: string;
	userId?: string;
	trackId: string;
	listeningTime: number;
	startTime: number;
	/**
	 * TODO: "music-together" is a wrong value for the context
	 * we are waiting for the backend to implement right context values
	 * then we will use it
	 */
	context?: STREAM_CONTEXT;
}) => {
	logCenter.log(
		new StreamLog({
			createdAt: Math.floor(startTime / 1000),
			isRestricted: true,
			isSynchronized: false,
			listeningDuration: Math.floor(listeningTime ?? 0),
			listenType: 'music_on_demand',
			media: {
				id: trackId,
				type: 'song',
			},
			streamContext: {
				id: groupId,
				type: context || 'music-together',
			},
			userId: userId || '0',
		}).serialize(),
	);
};

export const getScreenViewName = (value: boolean) => {
	return value
		? 'music_together_group_suggested_tracklist'
		: 'music_together_group_curated_tracklist';
};

export const logPlayClicked = ({
	groupId,
	playlistId,
	clickType,
	itemType,
	screenName,
	userId,
}: {
	userId?: string;
	groupId: string;
	playlistId: string;
	clickType: string;
	itemType: string;
	screenName: string;
}) => {
	return logCenter.log({
		type: TYPE,
		eventName: 'play_clicked',
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: screenName,
			screen_view_id_type: 'group',
			screen_view_id: groupId,
			click_type: clickType,
			item_type: itemType,
			item_id: playlistId,
		},
	});
};

export const logCustomEvent = ({
	groupId,
	playlistId,
	action,
	userId,
}: {
	userId?: string;
	groupId?: string;
	playlistId?: string;
	action: CustomEventActions;
}) =>
	logCenter.log({
		type: TYPE,
		eventName: 'music_together_used',
		user_id: userId || '0',
		customAttributes: {
			group_id: groupId,
			action,
			playlist_id: playlistId || undefined,
		},
	});

export const logListenOnDsp = ({
	groupId,
	platform,
	userId,
}: {
	userId?: string;
	groupId: string;
	platform: PLATFORM;
}) => {
	const platformSent =
		platform === PLATFORM.APPLEMUSIC ? 'apple_music' : platform.toLowerCase();

	logCenter.log({
		type: TYPE,
		eventName: 'music_together_used',
		user_id: userId || '0',
		customAttributes: {
			group_id: groupId,
			action: `listen_on_other_dsp_${platformSent}`,
		},
	});
};

export const logAddTrackToCurated = ({
	groupId,
	trackId,
	playlistId,
	context,
	userId,
}: {
	userId?: string;
	groupId: string;
	trackId: string;
	context: 'playlist_assistant' | 'suggested_tracklist';
	playlistId?: string;
}) => {
	return logCenter.log({
		type: TYPE,
		eventName: 'item_added_to_collection',
		user_id: userId || '0',
		customAttributes: {
			screen_view_name:
				context === 'suggested_tracklist'
					? 'music_together_group_suggested_tracklist'
					: 'music_together_group_curated_tracklist',
			screen_view_id: groupId,
			screen_view_id_type: 'group',
			collection_type: 'playlist',
			collection_id: playlistId || undefined,
			contextual_menu_action:
				context === 'playlist_assistant' ? 'playlist_assistant' : undefined,
			item_type: 'song',
			item_id: trackId,
		},
	});
};

export const logRemoveTrackToCurated = ({
	trackId,
	playlistId,
	userId,
}: {
	userId?: string;
	trackId: string;
	playlistId?: string;
}) => {
	return logCenter.log({
		type: TYPE,
		eventName: 'item_removed_from_collection',
		user_id: userId || '0',
		customAttributes: {
			collection_type: 'playlist',
			collection_id: playlistId || undefined,
			item_type: 'song',
			item_id: trackId,
		},
	});
};

export const logScreenViewUserAffinity = ({
	groupId,
	userId,
	clickType,
	itemType,
	itemId,
}: {
	userId: string;
	groupId: string;
	clickType?: 'item';
	itemType?: 'artist';
	itemId?: string;
}) => {
	return baseLogScreen({
		user_id: userId,
		customAttributes: {
			screen_view_name: 'music_together_user_affinity',
			screen_view_id_type: 'group',
			screen_view_id: groupId,
			click_type: clickType,
			item_type: itemType,
			item_id: itemId,
		},
	});
};

export const logClickOnProfile = ({
	groupId,
	itemId,
	screenViewName,
	userId,
	sectionName,
}: {
	groupId?: string;
	itemId: string;
	screenViewName: ScreenViewName;
	userId: string;
	sectionName?: SectionName;
}) => {
	return logCenter.log({
		type: TYPE,
		eventName: 'item_clicked',
		user_id: userId || '0',
		customAttributes: {
			screen_view_name: screenViewName,
			screen_view_id_type: 'group',
			screen_view_id: groupId,
			section_name: sectionName,
			item_id: itemId,
			item_type: 'user',
		},
	});
};

const eventLogs = {
	logScreenViewGroups,
	logScreenViewSuggestedTrackList,
	logScreenViewCuratedTrackList,
	logScreenViewGroupInformations,
	logScreenViewSharingMenu,
	logScreenJoinGroup,
	logNavigationAvatarBottomSheet,
	logStream,
	logPlayClicked,
	logCustomEvent,
	logScreenViewTrackInformations,
	logAddTrackToCurated,
	logRemoveTrackToCurated,
	logScreenViewLanding,
	logScreenViewConversionModal,
	logListenOnDsp,
	logScreenViewUserAffinity,
	logScreenViewGroupSetting,
	logClickOnProfile,
};

export default eventLogs;
