import {
	CircularProgressLabel,
	IconButton,
	Link,
	Text,
	VStack,
	CircularProgress,
	Flex,
} from '@tempo/core';
import type {FC} from 'react';
import React from 'react';
import {useTranslation} from 'next-i18next';
import {CheckIcon, CrossIcon} from '@tempo/icons';
import {AnimatePresence} from 'framer-motion';
import ToastContainer from './ToastContainer';

type ToastImportProps = {
	isComplete: boolean;
	percentage: number;
	onComplete?: () => void;
	onHide?: () => void;
	linkToGroup?: string;
};

export const DELAY_BEFORE_HIDE = 30000; // 30s

const ToastImport: FC<ToastImportProps> = ({
	isComplete,
	percentage,
	linkToGroup,
	onHide,
}) => {
	const [isShow, setIsShow] = React.useState(true);

	const {t} = useTranslation();

	const onHideToast = React.useCallback(() => {
		setIsShow(false);
		if (onHide) onHide();
	}, [setIsShow, onHide]);

	React.useEffect(() => {
		if (isComplete) {
			setTimeout(() => {
				onHideToast();
			}, DELAY_BEFORE_HIDE);
		}
	}, [isComplete, onHideToast]);

	const bodyWording = React.useMemo(() => {
		if (!isComplete) {
			return t('musictogethertoastmessage_text_addingmusic_web');
		}

		if (linkToGroup) {
			return (
				<>
					{t('musictogethertoastmessage_text_checkwhichtracksincommon_web')}{' '}
					<Text
						as={Link}
						href={linkToGroup}
						variant="body.2xs.default"
						color="text.accent.primary.default"
						fontWeight="bold"
					>
						{t('generic_action_letsgo_web')}
					</Text>
				</>
			);
		}

		return t('musictogethertoastmessage_text_creategroupsandinvitefriends_web');
	}, [linkToGroup, isComplete, t]);

	return (
		<AnimatePresence>
			{isShow && (
				<ToastContainer>
					<CircularProgress
						value={percentage}
						size="56px"
						color="background.accent.primary.default"
						thickness="6px"
					>
						<CircularProgressLabel
							as={Text}
							variant="body.m.default"
							color="text.neutral.primary.default"
							textAlign="center"
						>
							{isComplete ? (
								<CheckIcon display="inline-block" />
							) : (
								`${percentage}%`
							)}
						</CircularProgressLabel>
					</CircularProgress>

					<VStack spacing="spacing.xs" ml="spacing.xl" align="flex-start">
						<Flex justify="space-between" align="center" w="100%">
							<Text
								variant="body.xl.default"
								color="text.neutral.primary.default"
							>
								{isComplete
									? t('musictogethertoastmessage_title_importcomplete_web')
									: t('musictogethertoastmessage_title_importinprogress_web')}
							</Text>

							{isComplete && (
								<IconButton
									variant="ghost"
									onClick={onHideToast}
									aria-label={t('generic_action_close_web')}
									icon={<CrossIcon />}
									size="small"
								/>
							)}
						</Flex>

						<Text
							variant="body.2xs.default"
							color="text.neutral.secondary.default"
						>
							{bodyWording}
						</Text>
					</VStack>
				</ToastContainer>
			)}
		</AnimatePresence>
	);
};

ToastImport.displayName = 'ToastImport';

export default ToastImport;
