import React from 'react';
import {Drawer, DrawerContent} from '@tempo/core';
import Button from './Button';
import Header from './Header';
import Section from './Section';
import UserRow from './UserRow';

export type BottomSheetProps = {
	children: React.ReactNode;
	isOpen: boolean;
	onClose: () => void;
};

type SubComponents = {
	Header: typeof Header;
	/* SECTION */
	Section: typeof Section;
	Button: typeof Button;
	UserRow: typeof UserRow;
};

const BottomSheet: React.FC<BottomSheetProps> & SubComponents = ({
	children,
	onClose,
	isOpen,
}) => {
	return (
		<Drawer
			placement="bottom"
			onClose={onClose}
			isOpen={isOpen}
			autoFocus={false}
			returnFocusOnClose={false}
		>
			<DrawerContent
				bg="background.neutral.secondary.default !important"
				borderTopRadius="s"
				w="prose"
				mx="auto"
				pb="spacing.m"
				maxHeight="80vh"
				overflowY="auto"
			>
				{children}
			</DrawerContent>
		</Drawer>
	);
};

BottomSheet.Header = Header;

/* SECTION */
BottomSheet.Section = Section;
BottomSheet.Button = Button;
BottomSheet.UserRow = UserRow;

export default BottomSheet;
