import {Container as TempoContainer, type ContainerProps} from '@tempo/core';
import React from 'react';

const Container: React.FC<ContainerProps> = ({children, ...rest}) => (
	<TempoContainer
		p="spacing.l"
		bg="background.neutral.primary.default"
		{...rest}
	>
		{children}
	</TempoContainer>
);

export default Container;
