import {useApiCall} from '@deezer/react-legacy-api';
import {useCallback} from 'react';
import {useTranslation} from 'next-i18next';
import {SHARE_TYPE} from './useShortenUrl';

type UseInvitationLinkParams = {
	itemId: string;
	userId: string;
};

export type InvitationLink = {
	qrCodeUrl: string;
	url: string;
};

type InvitationLinkReturn = {
	qr_code_image_url: string;
	url: string;
};

type useFetchInvitationLinkProps = {
	getInvitationLink: ({
		itemId,
		userId,
	}: UseInvitationLinkParams) => Promise<InvitationLink>;
};

export function useFetchInvitationLink(): useFetchInvitationLinkProps {
	const {t} = useTranslation();
	const apiCall = useApiCall();

	const getInvitationLink = useCallback(
		({itemId, userId}: UseInvitationLinkParams) => {
			const baseUrl = `https://${window.location.hostname}/group/${itemId}/join?host=${userId}`;

			return apiCall({
				method: 'share.getDynamicLink',
				data: {
					link: baseUrl,
					type: SHARE_TYPE.GROUP_JOIN,
					GENERATE_QRCODE: true,
					id: itemId,
					sharing_platform: 'clipboard',
					sharing_format: 'generic',
					shorten_social_title: t(
						'musictogethersharingmenu_title_invitefriends_web',
					),
					shorten_social_description: t(
						'whatsappsharing_text_shakerinvitation_web',
					),
				},
			}).then((invitationLinkReturn) => {
				return {
					url: (invitationLinkReturn as InvitationLinkReturn).url,
					qrCodeUrl: (invitationLinkReturn as InvitationLinkReturn)
						.qr_code_image_url,
				} as InvitationLink;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[apiCall],
	);

	return {getInvitationLink};
}
