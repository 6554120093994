import React from 'react';
import BottomSheet from '@components/BottomSheet';
import CopyToClipboard from '@components/CopyToClipboard';
import {HumanPlusIcon} from '@tempo/icons';
import {Flex, Box, Text, Image} from '@tempo/core';
import {HEADER_SIZE} from '../BottomSheet/Header';
import {useTranslation} from 'next-i18next';
import useHandleInvitationLink from '@hooks/useHandleShareGroupInvitation';
import {useInvitationLink} from '@providers/InvitationLinkContext';

type BottomSheetShareProps = {
	isBottomSheetShareOpened: boolean;
	onCloseBottomSheetShare: () => void;
	groupId: string;
};

function BottomSheetShare({
	isBottomSheetShareOpened,
	onCloseBottomSheetShare,
	groupId,
}: BottomSheetShareProps) {
	const {t} = useTranslation();
	const {invitationLink} = useInvitationLink();

	const {
		hasCopied: hasCopiedFromBottomSheet,
		prepareShare: handleShareFromBottomSheet,
	} = useHandleInvitationLink({onCopyCallback: onCloseBottomSheetShare});

	if (!invitationLink?.url || !invitationLink?.qrCodeUrl) {
		return;
	}

	return (
		<BottomSheet
			isOpen={isBottomSheetShareOpened}
			onClose={onCloseBottomSheetShare}
		>
			<BottomSheet.Header size={HEADER_SIZE.S}>
				<BottomSheet.Header.Title>
					{t('musictogether_action_invitefriends_web')}
				</BottomSheet.Header.Title>
			</BottomSheet.Header>

			<BottomSheet.Section>
				<CopyToClipboard hasCopied={hasCopiedFromBottomSheet}>
					<BottomSheet.Button
						icon={<HumanPlusIcon />}
						onClick={() =>
							handleShareFromBottomSheet(groupId, invitationLink.url)
						}
					>
						{t('generic_title_shareinvitelink_web')}
					</BottomSheet.Button>
				</CopyToClipboard>
			</BottomSheet.Section>

			<BottomSheet.Section px="spacing.l" py="spacing.m">
				<Flex justify="space-between" pt="spacing.m">
					<Box>
						<Text
							variant="body.l.default"
							color="text.neutral.primary.default"
							textAlign="left"
							mb="spacing.s"
						>
							{t('generic_title_showqrcode_web')}
						</Text>
						<Text
							variant="body.l.default"
							color="text.neutral.secondary.default"
							textAlign="left"
						>
							{t('musictogether_text_friendswillbeinvitedtojoingroup_web')}
						</Text>
					</Box>
					<Image
						src={invitationLink.qrCodeUrl}
						alt="qrcode"
						width="96px"
						height="96px"
					/>
				</Flex>
			</BottomSheet.Section>
		</BottomSheet>
	);
}

export default BottomSheetShare;
