import React from 'react';
import {
	AppColorModeEnforcer,
	ColorModeScript,
	createLocalStorageManager,
	extendTheme,
	TempoProvider,
} from '@tempo/core';

const STORAGE_KEY = 'shaker-color-mode';
const localStorageManager = createLocalStorageManager(STORAGE_KEY);

const ThemeProvider: React.FC<{
	children?: React.ReactNode;
}> = ({children}) => {
	const theme = extendTheme({
		config: {
			initialColorMode: 'dark',
		},
	});

	return (
		<>
			<ColorModeScript
				storageKey={STORAGE_KEY}
				initialColorMode={theme.config.initialColorMode}
			/>
			<TempoProvider colorModeManager={localStorageManager} theme={theme}>
				<AppColorModeEnforcer colorMode={theme.config.initialColorMode} />
				{children}
			</TempoProvider>
		</>
	);
};

export default ThemeProvider;
