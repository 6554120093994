import React from 'react';

import ThemeProvider from '@providers/ThemeProvider';
import AuthProvider from '@providers/AuthenticationProvider';
import GqlProvider from '@providers/GraphQLProvider';
import ProtectedRouteProvider from '@providers/ProtectedRouteProvider';
import InvitationLinkProvider from '@providers/InvitationLinkProvider';
import BridgeProvider from '@providers/BridgeProvider';

import ApiProvider from '@deezer/react-legacy-api';
import LegacyUserProvider from '@deezer/react-user';
import UserProvider from '@providers/UserProvider';
import LogEventProvider from '@providers/LogEventProvider';
import {ConsentContextProvider} from '@tempo/cookie';

import Layout from '@components/Layout';
import ConsentScreen from '@components/CookieBanner';
import config from '@modules/config';
import type {Trackable} from '@customTypes/track';
import SwitchTMM from '@components/SwitchTMM';

interface AppRootProps {
	children: React.ReactNode;
	tracking?: Trackable;
}

const AppRoot: React.FC<AppRootProps> = ({children, tracking}) => {
	const protectedRoutes = ['/groups'];

	const deezerApiUrl = config.get('deezerApiUrl').replace(/https?:\/\//, '');
	const deezerImgUrl = config.get('deezerImagesUrl').replace(/https?:\/\//, '');
	const accountWebsiteSsl = config.get('accountWebsiteSsl');

	return (
		<AuthProvider>
			<GqlProvider>
				<ApiProvider host={deezerApiUrl} mode="cors" credentials="include">
					<LegacyUserProvider
						pictureDomain={deezerImgUrl}
						appName="Shaker"
						hostAccount={accountWebsiteSsl}
					>
						<UserProvider>
							<BridgeProvider>
								<ThemeProvider>
									<ConsentContextProvider>
										<LogEventProvider tracking={tracking}>
											<SwitchTMM>
												<ProtectedRouteProvider
													protectedRoutes={protectedRoutes}
												>
													<InvitationLinkProvider>
														<Layout>
															<ConsentScreen />
															{children}
														</Layout>
													</InvitationLinkProvider>
												</ProtectedRouteProvider>
											</SwitchTMM>
										</LogEventProvider>
									</ConsentContextProvider>
								</ThemeProvider>
							</BridgeProvider>
						</UserProvider>
					</LegacyUserProvider>
				</ApiProvider>
			</GqlProvider>
		</AuthProvider>
	);
};
export default AppRoot;
