import {type FlexProps, Flex, Avatar, Heading} from '@tempo/core';
import React from 'react';
import {useLogEvent} from '@providers/LogEventProvider';
import {useGroup} from '@providers/GroupProvider';
import type {ScreenViewName} from '@lib/logcenter/eventLog';
import {useUser} from '@providers/UserProvider';
import config from '@modules/config';

type UserRowProps = FlexProps & {
	avatar?: string;
	name: string;
	id: string;
	screenViewName?: ScreenViewName;
};

const UserRow: React.FC<UserRowProps> = ({
	children,
	avatar,
	name,
	id,
	screenViewName,
	...rest
}) => {
	const {eventLogs} = useLogEvent();
	const {groupId} = useGroup();
	const {user} = useUser();

	return (
		<Flex
			direction="row"
			px="spacing.l"
			py="spacing.s"
			align="center"
			as="button"
			_hover={{
				bg: 'background.neutral.secondary.pressed',
			}}
			_focus={{
				bg: 'background.neutral.secondary.pressed',
			}}
			{...rest}
			onClick={() => {
				if (user && groupId && id && eventLogs && screenViewName) {
					eventLogs.logClickOnProfile({
						userId: user.id,
						screenViewName,
						groupId: groupId,
						itemId: id,
					});
					window.location.href = `https://${config.get('host_website_ssl')}/profile/${id}`;
				}
			}}
		>
			<Avatar name={name} src={avatar} mr="spacing.s" />

			<Heading
				variant="heading.xs"
				as="h4"
				color="text.neutral.primary.default"
			>
				{children}
			</Heading>
		</Flex>
	);
};

export default UserRow;
