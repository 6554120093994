import {AuthBase, QUERY_PARAMS_TYPE, WithAnonymousLogin} from '@deezer/auth';
import config from '@modules/config';

const Auth = WithAnonymousLogin(AuthBase);

const authInstanceAnonymous = new Auth({
	tokenOutputType: QUERY_PARAMS_TYPE.PAYLOAD,
	refreshTokenOutputType: QUERY_PARAMS_TYPE.COOKIE,
	host: config.get('host_authent'),
});

export default authInstanceAnonymous;
